import * as React from 'react'
import { graphql } from 'gatsby'
import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import {callHook} from '../utils/utils';
var validator = require('email-validator')
import settings from '../settings.json'

export const BusinessCardForm = ({ slice, index }) => {
  const [isSend, setSend] = useState(false)
  const [isError, setError] = useState(false)
  const [loading, setLoading] = useState(false);
  const [hookResponse, setHookResponse] = useState({});
  

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onChange',
  })

  const { title, text_premium, img, img2 } = slice.primary

  let gclid = ""
  let msclkid = ""
  if (typeof window !== 'undefined'){
    const pageUrl = new URL(window.location);
    gclid = pageUrl.searchParams.get("gclid");
    //console.log('gclid: ', gclid);
    msclkid = pageUrl.searchParams.get("msclkid");
    //console.log('msclkid: ', msclkid);
  }

  const onSubmit = async (data) => {
    console.log('data: ', data);
    const url = "https://n8n.yohn.io/webhook/free-business-card"
    const mode = "no-cors"
    let qrData = {type: "FreeBusinessCard", email: data.email, data: "", gclid: gclid, msclkid: msclkid}

    const person = {
      "surname": data.lastName,
      "forename": data.firstName,
      "emailAddress": data.email,
      "mobileNumber": data.phone
    };

    const text = [
      "BEGIN:VCARD",
      "VERSION:3.0",
      `N:${data.lastName};${data.firstName}`,
      `FN:${data.firstName} ${data.lastName}`,
    ];

    if (data.email) {
      text.push(`EMAIL;TYPE=INTERNET:${data.email}`);
    }
    if (data.phone) {
      text.push(`TEL;CELL:${data.phone}`);
    }
    text.push("END:VCARD")

    const qr_pass = {
      "person": person,
      "vcard": text.join("\r\n")
    }

    qrData.data = text.join('\r\n');

    console.log('qr_pass: ', qr_pass);

    qrData.qrPass = qr_pass;
    setLoading(true);
    const r = await callHook({url, uid:'yohn-qr-code', params:qrData, mode});
    console.log('callHook r: ', r);
    setHookResponse(r);
    setSend(true);
    setLoading(false);
  }

  useEffect(() => {
    const before = document.getElementById('card-before');
    const after = document.getElementById('card-after');

    setTimeout(function(){
    before.style.opacity = isSend ? 0 : 1;
    before.style.transform = `translate(${isSend ? 100 :0}px, 0px)`;
    }, isSend ? 0 : 250)

    setTimeout(function(){
      after.style.opacity = isSend ? 1 : 0;
      after.style.transform = `translate(${isSend ? 0 : -100}px, 0px)`;
    }, isSend ? 250 : 0)

  }, [isSend]);

  const PremiumCTA = () => (
    <div className="bg-primary" style={isSend ? {background: "linear-gradient(152deg, rgba(50,37,37,1) 0%, rgba(11,5,5,1) 100%)"} : {} }>
      <div className="container py-5 px-0 px-lg-5">
        <div className="row justify-content-between g-3">
          <h3 className={`col-12 col-lg-8 text-center text-lg-start align-middle ${isSend ? `text-white` : ``}`}>{text_premium}</h3>
          <div className="col-12 col-lg-3 text-center me-0 me-lg-5">
            <a href="https://yo.hn/10euronly?token=" className={`btn btn-light text-uppercase`} style={isSend ? {background: "linear-gradient(152deg, rgba(242,142,38,1) 0%, rgba(253,100,79,1) 100%)", color:"#fff", borderColor:"transparent"} : {} }>Buy&nbsp;now!</a>
            <p className={`mt-2 mb-0 h6 ${isSend ? `text-white` : ``}`} style={{fontSize:"14px"}}><span style={{textDecorationLine:"line-through",textDecorationThickness:"0.1rem"}}>50</span> 10€ / year!</p>
          </div>
        </div>
      </div>
    </div>
  )

  const HowItWorks = () => (
    <div className="bg-white" id="how-do-digital-business-cards-work" >
      <div className="container pt-6">
        <h2 className={`text-center mb-5`}>{"How Do Digital Business Cards Work?"}</h2>
        <div className="row g-5">
          <div className="col-12 col-lg-4 text-center">
            <div className="">
              <img id="card-after" src={"https://images.prismic.io/colibri-live/ZtHQfkaF0TcGJl3h_how-cards-work-1.png?auto=format,compress"} alt="" className="img-fluid rounded-4 img-shadow" style={{maxHeight:"480px"}} loading="lazy"/>
              <h5 class="mt-4">1. Show the card on your phone to the recipient</h5>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <div className="" style={{maxHeight:"600px"}}>
              <img id="card-after" src={"https://images.prismic.io/colibri-live/ZqkcFx5LeNNTxmt6_how-cards-work-2.png?auto=format,compress"} alt="" className="img-fluid rounded-4 img-shadow" style={{maxHeight:"480px"}} loading="lazy"/>
              <h5 class="mt-4">2. They scan the QR on the card...</h5>
            </div>
          </div>
          <div className="col-12 col-lg-4 text-center">
            <div className="" style={{maxHeight:"600px"}}>
              <img id="card-after" src={"https://images.prismic.io/colibri-live/ZtHQfkaF0TcGJl3g_how-cards-work-3.png?auto=format,compress"} alt="" className="img-fluid rounded-4 img-shadow" style={{maxHeight:"480px"}} loading="lazy"/>
              <h5 class="mt-4">3. ...and save the contact data to their phone instantly</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  return (
    <>
      <div className={'py-6'}>
        <div className={'container'}>
          <div className="row g-5">
            <div className="col pt-lg-5">
              {isSend &&
                <h4 className="text-center mb-4 mt-lg-5">We have sent your free business card to your email.</h4>
              }
              <h1 className="h2 mb-0 text-center" id="title">{!isSend ? title : text_premium}</h1>
              {!isSend ? 
                <div className="mt-5">
                  <form
                    id="freebusinesscard"
                    className="text-center col"
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <div className="row g-3">
                      <div className={'position-relative d-flex col-12 col-md-6'}>
                        <input
                          placeholder="First name *"
                          className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                          {...register('firstName', { required: true })}
                        />
                      </div>
                      <div className={'position-relative d-flex col-12 col-md-6'}>
                        <input
                          placeholder="Last name *"
                          className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                          {...register('lastName', { required: true })}
                        />
                      </div>
                      <div className={'position-relative d-flex flex-column col-12 col-md-6'}>
                        <input
                          placeholder="Email *"
                          className={'form-control' + ' ' + (errors.email && 'is-invalid')}
                          {...register('email', {required: true, validate: (input) => validator.validate(input)})}
                        />
                        <div className="invalid-feedback" wfd-invisible="true">{'Invalid email address'}</div>
                      </div>
                      <div className={'position-relative d-flex col-12 col-md-6'} style={{height:"fit-content"}}>
                        <input
                          placeholder="Phone"
                          className={'form-control' + ' ' + (errors.full && 'is-invalid')}
                          {...register('phone', { required: false })}
                        />
                      </div>
                    </div>
                    <div className={'d-flex justify-content-center mt-4'}>
                      <label className="form-check-label" for="flexCheckChecked">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          value=""
                          id="flexCheckChecked"
                          required
                        />
                        <span className="fs-0875">
                          I agree to the <a href="/terms/">Terms of Service</a>,{' '}
                          <a href="/privacy-policy/">Privacy Policy</a> and Use of
                          Cookies.
                        </span>
                      </label>
                    </div>
                    <input
                      type="submit"
                      value="Generate Now!"
                      className={'mt-4 btn btn-sm btn-primary text-uppercase'}
                    />
                  </form>
                  <p className="mt-3 mb-0 h6 text-center" style={{fontSize:"14px"}}>Test for 7 days - absolutely for free, no credit card needed</p>
                </div>
              :
                <div className="text-center mx-auto">
                  <img src={img2.url} alt={img2.alt} className="d-lg-none img-fluid rounded-4 img-shadow mt-4" style={{maxHeight:"600px"}} loading="lazy"/>
                  <div className="col-auto mt-4">
                    {!isError ?
                      <div className="">
                        <a href="https://yo.hn/10euronly?token=" className={"btn btn text-uppercase btn-primary mb-3"} style={isSend ? {background: "linear-gradient(152deg, rgba(242,142,38,1) 0%, rgba(253,100,79,1) 100%)", color:"#fff", borderColor:"transparent"} : {} }>Buy&nbsp;now!</a><br/>
                        <a onClick={()=>setSend(false)} className={"h6"} style={{cursor:"pointer"}}>{"Generate another card"}</a>
                      </div>
                    :
                      <>
                        <h3>An error has occurred</h3>
                        <p>Please try again later, server is not available now.</p>
                        <button onClick={()=>setSend(false)} className={"btn btn-sm text-uppercase btn-primary"}>{"Try again"}</button>
                      </>
                    }
                  </div>
                </div>
              }
            </div>
            <div className="col-auto d-none d-lg-flex flex-column justify-content-center align-items-center ps-5" style={{maxHeight:"600px"}}>
              <img id="card-before" src={img.url} alt={img.alt} className="img-fluid rounded-4 img-shadow position-relative" style={{maxHeight:"600px", opacity:"1", transition: "all .25s ease"}} loading="lazy"/>
              <img id="card-after" src={img2.url} alt={img2.alt} className="img-fluid rounded-4 img-shadow position-absolute" style={{maxHeight:"600px", opacity:"0", transition: "all .25s ease"}} loading="lazy"/>
            </div>
          </div>
        </div>
      </div>
      <PremiumCTA/>
      <HowItWorks/>
    </>
  )
}

export const query = graphql`
  fragment PageDataBodyBusinessCardForm on PrismicPageDataBodyBusinessCardForm {
    primary {
      title
      text_premium
      img {
        url
        alt
      }
      img2 {
        url
        alt
      }
    }
  }
`
